@import styles/functions
@import styles/utils/libs
@import styles/utils/vars
@import styles/utils/mixins
@import styles/utils/fonts

ul, ol
	-webkit-padding-start: 1.175em

*
	box-sizing: border-box

svg
	max-width: 100%
	height: auto

html, body
	font-family: $font
	font-size: 16px
	font-weight: normal
	line-height: 1.275
	color: $black
	overflow-x: hidden

.wrapper
	display: flex
	flex-direction: column
	min-height: 100vh

.content
	margin-bottom: 4em
	flex: 1
	position: relative

.intro-block
	.text
		padding-right: 2em

.forgot-pass
	position: relative
	top: -1em
	display: inline-block
	margin-bottom: 1em
	font-size: 0.9em
	color: $gray
	border-bottom: 1px dashed $gray
	text-decoration: none

.coin-count
	display: inline-block
	span
		margin-right: 0.25em
	img
		position: relative
		top: 0.1em

body
	.MuiSelect-select:not([multiple])
		option,
		optgroup
			color: #000000

//Модули
@import styles/modules/modules
@import styles/media
