// Auth pages
.auth-pages
	display: flex
	justify-content: space-between
	align-items: flex-start
	max-width: 1000px
	margin: auto
	.auth-form
		padding-right: 5em
		flex-grow: 1
	.another-action
		padding: 2.5em
		box-shadow: 0px 1px 4px 0 rgba(30, 45, 62, 0.2)
		width: 350px
		flex-basis: 350px
		border-radius: 3px
	.social-auth
		display: flex
		justify-content: center
		span
			display: inline-flex
			width: 40px
			height: 40px
			justify-content: center
			align-items: center
			border-radius: 40px
			cursor: pointer
			.spinner
				display: none
			&.facebook
				background-color: #3851A8
				.spinner
					color: rgba(255, 255, 255, 0.8)
			&.google
				border: 1px solid #EDE8FE
				margin-left: 1.5em
			&.loading
				img
					display: none
				.spinner
					display: block

.other-actions
	.MuiListItem-button
		display: block
		padding: 0.5em 1.75em
		color: #000000
		text-decoration: none
		text-align: center
		border-top: 1px solid $color-2
		font-size: 0.9em
		color: $color
		font-weight: 600
		&:last-child
			color: $error
		.MuiTypography-body1
			font-family: $font
	.MuiList-padding
		padding-top: 0
		padding-bottom: 0

.balance-info
	margin-top: 2em
	font-size: 0.85em
	text-align: left
	color: $gray

.user-panel
	display: flex
	justify-content: space-between
	.balance
		max-width: 100%
		border: 1px solid $color-2
		border-radius: 5px
		text-align: center
		width: 400px
		flex-basis: 400px
		flex-shrink: 0
		align-self: flex-start
		&__inner
			padding: 1.75em
		.balance-count
			> *
				vertical-align: middle
			img
				width: 1.5em
				margin-left: 0.25em
		.btn.btn-colored-2
			margin-right: 2em
	.poll-list
		flex: 1
		padding-right: 3em
		position: relative
		&--empty
			display: flex
			flex-direction: column
		.spinner
			font-size: 2em
			margin: 2em 0
		.vertical-centered
			display: flex
			align-items: center
			justify-content: center
			flex: 1
		h4
			color: $color-2
			margin: 3em 0
			font-size: 1.25em
			font-weight: 600
		table
			width: 100%
			border-spacing: 0
			tr
				td
					padding: 0.75em 0
					.coin-count
						white-space: nowrap
						padding: 0 1.5em
					.MuiButton-root
						color: $color
						border-color: $color-2
						border-radius: 50px
						font-size: 0.75em
						padding: 0.75em 2em
						text-transform: inherit
						font-weight: 600
						line-height: 1.275
						&.poll-status-1
							border-color: $color-secondary
							background-color: $color-secondary
							color: #ffffff
					&:not(:first-child)
						text-align: right
				&:not(:last-child)
					td
						border-bottom: 1px solid $color-2

// Dialogs
.custom-lemur-dialog
	.MuiDialog-container
		.MuiTypography-h6
			font-family: $font
		.spinner
			font-size: 3em
			margin: 1em auto

body
	.MuiButton-root
		font-family: $font
	.MuiButton-textPrimary
		color: $color
	.MuiDialogContent-root
		position: relative
		
.withdraw-container
	width: 400px
	margin: auto
	max-width: 100%

.tabletochki
	margin-bottom: 2em
	.image
		margin-left: -35px
		img
			width: 250px
			max-width: 100%
	p
		margin-bottom: 0
		margin-top: 1em
		font-size: 0.9em
	a
		color: #ffffff
	
.withdraw
	background-color: $color
	color: #ffffff
	border-radius: 6px
	padding: 2em
	.balance
		margin-bottom: 1.25em
		font-size: 1.25em
		text-align: center
	.field label:not(.MuiFormControlLabel-root)
		color: rgba(255, 255, 255, 0.8)
	.MuiInputBase-root
		color: #ffffff

.poll-card
	background-color: $color
	color: #ffffff
	border-radius: 6px
	padding: 2em
	width: 600px
	margin: auto
	max-width: 100%
	.coin
		margin-left: 0.25em !important
		position: relative
		top: 1px
	.label
		margin-bottom: 0.5em
		color: rgba(255, 255, 255, 0.5)
		font-size: .75em
	.select-language
		.MuiToggleButtonGroup-root
			display: flex
		.MuiToggleButton-root
			color: #ffffff
			font-family: $font
			border-color: #ffffff
			flex: 1
			&.Mui-selected
				background-color: #ffffff
				color: $color
	.poll-empty,
	.thanks-poll
		text-align: center
		img
			margin: auto
		h3
			margin-bottom: 0
			margin-top: 1em
	.thanks-poll
		h4
			margin-top: 0.5em
			font-weight: normal
		.MuiButton-root
			border-color: $color-secondary
			background-color: $color-secondary
			border-radius: 50px
			color: #ffffff
			font-size: 0.85em
			display: block
			width: 100%
			padding: 1em 0
			margin-top: 2em
	.spinner-container
		text-align: center
		margin: 2em 0
		.spinner
			font-size: 2em
			color: #ffffff
			margin: auto
		p
			color: rgba(255, 255, 255, 0.7)
			margin-top: 1em

.intro-block,
.about-block
	.icon
		flex-basis: 50%
		flex-shrink: 0

.user-info-header
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: 2em
	h2
		margin-bottom: 0
	.MuiButton-outlined
		border-color: $error
		color: $error

img
	max-width: 100%

.app-block
	padding-top: 8em
	h2
		text-align: center
	p
		text-align: center
		width: 800px
		max-width: 100%
		margin: auto auto 3em
	&-image
		text-align: center
		img
			width: 400px
			max-width: 100%
	.available-app
		text-align: center
		margin: 1em auto 0
		width: 300px
		max-width: 100%
		display: flex
		justify-content: space-between
		a
			display: inline-block