.btn
	padding: 1.5em 4.5em
	text-align: center
	background-color: transparent
	border-radius: 5em
	text-decoration: none
	display: inline-block
	transition: opacity 250ms linear
	outline: none
	border: 0
	cursor: pointer
	font-weight: 600
	position: relative
	&.disabled
		cursor: default
		opacity: 0.75
	&:not(.disabled)
		&:hover
			opacity: 0.9
		&:active
			transform: translateY(1px)
	.spinner
		position: absolute
		z-index: 1
		top: 0
		left: 0
		right: 0
		bottom: 0
		margin: auto
		display: none
		font-size: 1.5rem
	&.loading
		color: transparent
		.spinner
			display: block

.btn-full
	display: block
	width: 100%
	padding-left: 0
	padding-right: 0

.btn-bordered
	border: 1px solid $color-2
	color: $color

.btn-white
	color: $color
	background-color: #fff

.btn-colored
	background-color: $color
	color: #ffffff

.btn-colored,
.btn-colored-second
	.spinner
		i
			background: rgba(255, 255, 255, 0.7)

.btn-colored-2
	background-color: $color-4
	color: $color

.btn-colored-second
	background-color: $color-secondary
	color: #ffffff

.btn-md
	font-size: 0.85em
	padding: 1em 2.5em

.btn-sm
	font-size: 0.75em
	padding: 0.75em 2em

.btn-color-shadow
	box-shadow: 0 3px 16px $color-2

.btn-color-shadow-second
	box-shadow: 0 3px 16px $color-secondary