// Colors
$black: #181818
$light-gray: #F6F6F6
$gray: #776D93
$color: #327cc6
$color-2: lighten($color, 34%)
$color-3: #E2DFEF
$color-4: lighten($color, 41%)
$color-secondary: #ffab00
$error: #f44336
$warning: #ff9800
$success: #4caf50
// Sizes
$base-font-size: 16px

$content-size: 1170px

// Fonts
$font: 'Montserrat', sans-serif
// Transitions

// Images

// z-index