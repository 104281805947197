.modal-data-list
    h4
        margin-bottom: 0.5em
        &:not(:first-child)
            margin-top: 1em
    &--empty
        color: $color-2
        margin: 2em 0
        font-size: 1.25em
        font-weight: 600
        text-align: center
    &--item
        display: flex
        align-items: center
        justify-content: space-between
        padding: .5em 0
        border-bottom: 1px solid $color-3
        > div
            .warning
                color: $warning
            .success
                color: $success
            .cancel
                color: $error
            &:first-child
                padding-right: 2em
                display: inline-flex
                align-items: center
                span
                    display: inline-block
                    vertical-align: middle
                    padding-right: 0.25em
                    svg
                        display: block
                        font-size: 1.75rem
            &:last-child
                white-space: nowrap
                img
                    position: relative
                    top: 1px
                    margin-left: .25em
                    max-width: 100px