svg:not(.prefix__animated) .animable
	opacity: 0

	body
		background: #FFFFFF

.prefix__animated #prefix__background-simple--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft
	animation-delay: 0s

.prefix__animated #prefix__Shadow--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomIn
	animation-delay: 0s

.prefix__animated #prefix__Plants--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight
	animation-delay: 0s

.prefix__animated #prefix__Checklist--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp
	animation-delay: 0s

.prefix__animated #prefix__Character--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp
	animation-delay: 0s

.prefix__animated #prefix__Floor--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut
	animation-delay: 0s

.prefix__animated #prefix__Shadows--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight
	animation-delay: 0s

.prefix__animated #prefix__Clouds--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn
	animation-delay: 0s

.prefix__animated #prefix__Plants--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideRight
	animation-delay: 0s

.prefix__animated #prefix__Document--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown
	animation-delay: 0s

.prefix__animated #prefix__character-2--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut
	animation-delay: 0s

.prefix__animated #prefix__character-1--inject-1
	animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) zoomOut
	animation-delay: 0s

@keyframes zoomOut
	0%
		opacity: 0
		transform: scale(1.5)
	100%
		opacity: 1
		transform: scale(1)

@keyframes fadeIn
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes slideDown
	0%
		opacity: 0
		transform: translateY(-30px)
	100%
		opacity: 1
		transform: translateY(0)

@keyframes slideLeft
	0%
		opacity: 0
		transform: translateX(-30px)
	100%
		opacity: 1
		transform: translateX(0)

@keyframes zoomIn
	0%
		opacity: 0
		transform: scale(0.5)
	100%
		opacity: 1
		transform: scale(1)

@keyframes slideRight
	0%
		opacity: 0
		transform: translateX(30px)
	100%
		opacity: 1
		transform: translateX(0)

@keyframes slideUp
	0%
		opacity: 0
		transform: translateY(30px)
	100%
		opacity: 1
		transform: inherit
