.field
	display: block
	margin-bottom: 2em
	input
		display: block
		background-color: transparent
		box-shadow: none
		padding: 0.5em 0
		border-style: solid
		border-color: $color-2
		border-width: 0 0 1px
		width: 100%
		outline: none
		font-family: $font
	.MuiInput-underline
		&::after
			border-bottom: 2px solid $color-2
		&::before
			border-bottom: 1px solid $color-4
		&:hover:not(.Mui-disabled):before
			border-bottom: 2px solid $color-4
	.MuiFormLabel-root.Mui-focused
		color: $color
	label
		font-size: 0.9em
		display: block
		font-family: $font
		&:not(.MuiFormControlLabel-root)
			margin-bottom: 0.5em
			color: $gray
	.MuiTypography-body1
		font-family: $font
		position: relative
		top: 3px
body
	.MuiFormLabel-root
		font-family: $font
		&.Mui-focused
			color: $color
	.MuiRadio-colorPrimary.Mui-checked
		color: $color-secondary