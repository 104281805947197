.app-loader
    background-color: $color
    height: 100vh
    width: 100vw
    display: flex
    align-items: center
    justify-content: center
    &__inner
        img
            width: 120px
        .spinner
            font-size: 1.75em
            margin: .75em auto 0
            color: rgba(255, 255, 255, 0.6)