@media screen and ( max-width: 1050px )
    .intro-block,
    .about-block
        .icon
            flex-basis: 30%
    .user-panel
        flex-direction: column-reverse
        .balance
            margin: auto auto 3em
            flex-basis: auto
        .poll-list
            padding-right: 0
            width: 100%

@media screen and ( max-width: 959px )
    .modal-data-list--empty,
    .modal-data-spinner
        display: flex
        justify-content: center
        position: absolute
        z-index: 1
        margin: auto
        top: 0
        right: 0
        left: 0
        bottom: 0
        align-items: center

@media screen and ( max-width: 768px )
    .auth-pages
        flex-direction: column
        .auth-form
            flex-grow: 0
            padding-right: 0
            width: 100%
            margin-bottom: 4em
        .another-action
            flex-basis: auto
            margin: auto
            max-width: 100%
    body
        font-size: 14px
    .intro-block
        flex-direction: column-reverse
        margin-bottom: 4em
        .text
            padding-right: 0
            > div:last-child
                text-align: center
    .about-block
        flex-direction: column
        .text
            padding-left: 0
            margin-top: 2em
    header,
    .content
        margin-bottom: 2em

@media screen and ( max-width: 500px )
    .poll-card
        padding: 1em
    .container
        padding: 0 1.5em
    header
        margin-bottom: 0
        .header-content
            flex-direction: column
        .right-side
            margin-top: 1em
            width: 100%
            justify-content: space-between
        .auth-block
            margin-left: 0
    .user-panel .balance__inner
        padding: 1em
    .other-actions .MuiListItem-button
        padding: 0.3em 1em
    .auth-form
        [type="submit"]
            width: 100%
    .auth-pages .another-action
        padding: 1.5em
        a
            width: 100%
            display: block
    .about-block
        .text
            > div:last-child
                margin-top: 2em
                .btn
                    width: 100%
                    &:first-child
                        margin-bottom: 1em