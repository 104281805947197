header
	margin-bottom: 4em
	.logo
		text-decoration: none
		color: $black
	.header-content
		padding: 1.5em 0
	.auth-block
		margin-left: 3.5em
		> a:nth-child(2)
			margin-left: 1em
	.langs
		span
			&.active
				color: $color-secondary
			&:not(.active)
				cursor: pointer
			&:not(:last-child)
				margin-right: 0.9em
	.user-button
		border-radius: 50px
		text-decoration: none
		display: inline-block
		outline: none
		font-weight: 600
		font-size: 0.75em
		padding: 0.75em 2em
		border: 1px solid $color-2
		color: $color
		line-height: 1.275
		text-transform: inherit
	.header-news
		padding: 1em 0
		background-color: $color-4
		.text
			text-transform: uppercase
			font-size: 1.25em
		.action
			padding-left: 2em
			@media screen and (max-width: 768px)
				padding-left: 0
				padding-top: 1em
			.MuiButton-root
				border-radius: 50px
				background-color: $color
				color: #fff
				border-color: $color
		@media screen and (max-width: 768px)
			text-align: center
			.flex
				flex-direction: column
