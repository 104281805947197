footer
	border-top: 1px solid $color-2
	margin-top: 6.25em
	padding: 1.5em 0
	text-align: center
	margin-top: auto
	font-size: 0.85em
	.footer-inner
		display: flex
		flex-wrap: wrap
		justify-content: center
		.footer-item
			padding: 0.5em 1em
			a
				color: $color