$blade-count: 12
$blade-delay: 80ms
$blade-rotation-increment: 360deg / $blade-count

.spinner
	display: flex
	position: relative
	font-size: 1rem
	width: 1.25em
	height: 1.25em
	color: rgba(0, 0, 0, .35)
	i
		position: absolute
		left: 50%
		width: .125em
		height: .3125em
		margin-left: -.0625em
		background: currentColor
		border-radius: .05em
		transform-origin: 50% .625em
		opacity: .2
		animation: spinner-blade-opacity $blade-count * $blade-delay infinite
	@for $i from 1 through $blade-count
		i:nth-child(#{$i + 1})
			transform: rotateZ($blade-rotation-increment * $i)
			animation-delay: $blade-delay * $i

@keyframes spinner-blade-opacity
	from
		opacity: 1
	to
		opacity: .2
