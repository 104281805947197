.error-page
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex: 1
    position: absolute
    z-index: 1
    left: .5em
    right: .5em
    bottom: 0
    top: 0
    padding: 0 1em
    &--code
        font-size: 4em
        font-weight: bold
        img
            height: 1.2em
            margin-bottom: -10px
            margin-left: 10px
            margin-right: 10px
    &--text
        color: $color
        margin-top: .5em
        font-size: 2em
        text-align: center