h1, h2, h3, h4, h5, h6
	margin-top: 0

h1, h2, h3, h5
	font-weight: 600
	line-height: 1.25
	margin-bottom: 1.1em
	text-transform: uppercase

h1
	font-size: 2.25em
	letter-spacing: 0.1em

h2
	font-size: 1.75em

h3
	letter-spacing: 0.1em
	font-size: 1.25em

h5
	font-size: 16px
	letter-spacing: 0.05em

p
	margin-top: 0
	margin-bottom: 1em