.container
	max-width: 1140px
	margin: auto
	padding: 0 3em

.row
	margin-left: -1em
	margin-right: -1em

.col
	padding-left: 1em
	padding-right: 1em

.flex
	display: flex

.flex-wrap
	flex-wrap: wrap

.items-center
	align-items: center

.space-between
	justify-content: space-between

.align-center
	align-items: center

.text-center
	text-align: center

.m-auto
	margin: auto

@for $i from 1 through 10
	.mb-#{$i}em
		margin-bottom: #{$i}em
	.mt-#{$i}em
		margin-top: #{$i}em
	.ml-#{$i}em
		margin-left: #{$i}em
	.mr-#{$i}em
		margin-right: #{$i}em
	.pb-#{$i}em
		padding-bottom: #{$i}em
	.pt-#{$i}em
		padding-top: #{$i}em
	.pl-#{$i}em
		padding-left: #{$i}em
	.pr-#{$i}em
		padding-right: #{$i}em

@for $i from 3 through 11
	$width: $i*100
	.max-width-#{$width}
		max-width: #{$width}px

.prefix__svg-color
	fill: $color-secondary

.bordered-text
	opacity: 0.2
	color: $color
	overflow: hidden
	font-weight: 700
	font-size: 1.1em
	text-align: center
	&::after,
	&::before
		content: ''
		display: inline-block
		vertical-align: middle
		width: 100%
		height: 1px
		background: $color
		border: solid #fff
		border-width: 0 5px
	&::before
		margin-left: -100%
	&::after
		margin-right: -100%

.up
	text-transform: uppercase

.semibold
	font-weight: semibold

// Colors
.c-main
	color: $color

@for $i from 1 through 20
	$size: $i*0.5
	.size-#{str-replace('' + $size, '.', '-')}
		font-size: #{$size}em